import { gsap, Elastic, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    const el = document.querySelector('.js-introdots');
    
    const init = ()=> {  
        const intro = document.querySelectorAll('.c-intro'),
              dots = el.querySelectorAll('i');

        const reset_dots = (idx) => {
            for (let i = 0; i < dots.length; i++) {
                dots[i].classList.remove('is-active');
            }
            dots[idx].classList.add('is-active');
            
            if (idx === '2') {
                
                el.classList.add('is-black');
            } else {
                el.classList.remove('is-black');
            }
        }

        intro.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    onEnter: () => {
                        reset_dots(item.dataset.id);
                        console.log('onEnter:' + item.dataset.id );
                        el.classList.add('fade-in');
                        
                    },
                   

                    onLeave: () => {
                       // if (item.dataset.id === 0) {
                            //console.log('onLeave' )
                        //}
                        
                        if (item.dataset.id === '2') {
                           el.classList.remove('fade-in');
                        }
                    },
                    
                    onLeaveBack: () => {
                        //console.log('onLeaveBack ' + item.dataset.id)
                        if (item.dataset.id === '0') {
                           el.classList.remove('fade-in');
                        }
                    },


                    onEnterBack: () => {
                        reset_dots(item.dataset.id);
                        console.log('onEnterBack:' + item.dataset.id);
                        
                        if (item.dataset.id === '2') {
                           el.classList.add('fade-in');
                        }
                    }
                },
            });
        });
/*

        ScrollTrigger.create({
            trigger: intro,
            start: "top bottom",
            onEnter: (e) => {
                console.clear(e);
                console.log("hit going forward!")
            },
            onEnterBack: () => {
                console.log("hit going enter back")
            },
            onLeaveBack: () => {
                console.clear();
                console.log("hit going backward")
            }
        });
*/
    };
    
    el ? init() : false;
    
}, false);