(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Inter+Tight:400,600:latin-ext', 'Bona+Nova:400,600:latin-ext']
        }
    });
})();

