import css from './sass/style.scss';

require('./js/anims')
require('./js/carousels')
require('./js/cooperatewithus')
require('./js/fonts')
require('./js/init')
require('./js/introdots')
require('./js/nav')
require('./js/polyfills')
require('./js/smoothscroll')
